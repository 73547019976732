<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { QTableColumn, QTable, QTd, QIcon, QTooltip } from 'quasar';
import { auth4Sdks } from '@mfl/platform-shell';
import {
  WsButton,
  WsBadge,
  WsMenu,
  WsMenuItem,
  WsIconButton,
  openDialog,
  WsTooltip,
  toast,
  ToastStatus,
} from '@mfl/common-components';
import {
  linksGateway,
  Link,
  DeleteResponse,
  ReportDataRow,
  CHUNK_SIZE,
} from '@wisestamp/links-gateway-sdk';
import GenerateQrDialog from './generate-qr-dialog.vue';

import strings from './links.strings';
import { addEditLink } from './add-edit-link';
import logo from './assets/empty-state.png';
import DeleteLinkDialog from './delete-link-dialog.vue';
import { buildURL, limitReachedPrompt } from './utils';

interface linkClicks extends Link {
  clicks?: number;
  lastClick?: string;
}

interface reportColumnTitle {
  [key: string]: string;
}

// Create a reactive state for links
const links = ref<linkClicks[]>([]);
const isLoading = ref<boolean>(true);
const copyTooltipText = ref<string>(strings.copyLink);

const columns: QTableColumn[] = [
  {
    name: 'title',
    label: strings.tableTitleColumn,
    field: 'title',
    align: 'left',
    sortable: true,
  },
  {
    name: 'url',
    label: strings.tableUrlColumn,
    field: 'redirectUrl',
    align: 'left',
  },
  {
    name: 'link',
    label: strings.tableLinkColumn,
    align: 'left',
    field: 'link',
  },
  {
    name: 'clicks',
    label: strings.tableClicksColumn,
    field: 'clicks',
    align: 'center',
    sortable: true,
  },
  {
    name: 'owner',
    label: strings.tableOwnerColumn,
    align: 'left',
    field: 'ownerEmail',
  },
  {
    name: 'createdAt',
    label: strings.tableCreatedColumn,
    field: (row: Link) => row.createdAt,
    format: (val: number) =>
      new Date(val).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    align: 'left',
    sortable: true,
  },
  {
    name: 'action',
    label: '',
    field: 'action',
    align: 'center',
  },
];

onMounted(async () => {
  await linksGateway.init(auth4Sdks());
  await fetchLinks();
});

const fetchLinks = async () => {
  try {
    const fetchedLinks = await linksGateway.list({
      includeAnalytics: true,
    });

    links.value = fetchedLinks.links || [];
    isLoading.value = false;

    const clicks = fetchedLinks.clicks || [];

    links.value.forEach((link) => {
      const click = clicks.find((linkClick) => linkClick.linkId === link.id)!;

      link.clicks = click?.clicks;

      if (click?.lastClick) {
        link.lastClick = new Date(click?.lastClick).toLocaleDateString(
          'en-US',
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          }
        );
      }
    });
  } catch (_err) {
    // TODO: inform the user
    console.error('Error fetching links. Please try again later.');
  } finally {
    isLoading.value = false;
  }
};

async function openDeleteLinkDialog(link?: Link): Promise<void> {
  const res = await openDialog({
    aid: 'DELETE_LINK_DIALOG',
    headerText: strings.dialogTitleDelete,
    component: DeleteLinkDialog,
    showTopSeparator: false,
    input: link,
  });
  if (!res) return;

  const result = res as DeleteResponse;
  if (result.success && result.deleteCount === 1) {
    links.value = links.value.filter((l) => link?.id !== l.id);
  }
}

async function openAddEditLinkDialog(link?: Link) {
  const isNewLink = link === undefined;
  if (isNewLink) {
    const { limitIsReached } = await linksGateway.isLimitReached({});
    if (limitIsReached) {
      await limitReachedPrompt();
      return;
    }
  }
  const newOrUpdatedLink = await addEditLink(link);

  // Dialog was dismissed
  if (!newOrUpdatedLink) return;

  if (isNewLink) {
    links.value.unshift(newOrUpdatedLink);
  } else {
    const index = links.value.findIndex((l) => l.id === link.id);

    if (index !== -1) {
      if (
        !newOrUpdatedLink.notificationEmails?.length &&
        !newOrUpdatedLink.notificationEmail
      ) {
        links.value[index].notificationEmails = [];
        links.value[index].notificationEmail = '';
      }
      Object.assign(links.value[index], newOrUpdatedLink);
    }
  }
}

const copyLink = async (text: string) => {
  await navigator.clipboard.writeText(text);
  copyTooltipText.value = strings.copiedLink;
  await new Promise((res) => setTimeout(res, 2000));
  copyTooltipText.value = strings.copyLink;
};

const generateQR = async (link: string, title: string) => {
  return await openDialog({
    aid: 'GENERATE_QR_DIALOG',
    headerText: 'Share your QR code',
    component: GenerateQrDialog,
    showTopSeparator: true,
    input: {
      redirectUrl: link,
      title,
    },
  });
};

const exportReport = async (link: linkClicks) => {
  if (!link.clicks) return;

  let csvData: ReportDataRow[] = [];
  const t = toast({
    status: ToastStatus.Loading,
    message: strings.downloadStarted,
    aid: 'EXPORT_REPORT_STARTED',
  });
  csvData = await downloadCsv(link, 1, csvData);
  t.dismiss();
  toast({
    message: strings.downloadCompleted,
    aid: 'EXPORT_REPORT_COMPLETED',
  });
  saveCsv(csvData || [], link);
};

const downloadCsv = async (
  link: Link,
  page: number = 1,
  csvData: ReportDataRow[] = []
): Promise<ReportDataRow[]> => {
  const reportData = await linksGateway.getReport({ linkId: link.id, page });
  csvData = csvData.concat(reportData.reportData || []);
  if (reportData.totalEntries && reportData.totalEntries > page * CHUNK_SIZE) {
    csvData = await downloadCsv(link, page + 1, csvData);
  }
  return csvData;
};

function saveCsv(data: ReportDataRow[], link: Link) {
  //convert data title to csv string
  const reportTitle: reportColumnTitle = strings.csvReportColumnTitles;
  const dataTitle =
    Object.keys(data[0])
      .map((it) => {
        return `"${reportTitle[it]}"`;
      })
      .join(',') + '\n';

  //convert data to csv string
  const dataRows = data
    .map((it) => {
      it.source =
        it.source == 'qr-code' ? strings.qrCodeSource : strings.linkSource;

      const regionData = it.region?.split(',');
      if (regionData && regionData.length > 1) {
        const country = regionData.shift();
        regionData.push(country || '');
        it.region = regionData.join(', ').replace(/^,/, '').trim();
      }

      if (it.dateTime) {
        const dateTime = new Date(it.dateTime);
        it.dateTime = dateTime.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      }

      return Object.values(it)
        .map((i) => {
          if (i === true || i === false) return it ? strings.yes : strings.no;
          return `"${i}"`;
        })
        .toString();
    })
    .join('\n');

  const blob = new Blob(['\ufeff' + dataTitle + dataRows], {
    type: 'text/csv;charset=utf-8',
  });
  const linkElement = document.createElement('a');
  linkElement.setAttribute('download', `${link.title}_link_report.csv`);
  const hrefAttrValue = URL.createObjectURL(blob);
  linkElement.setAttribute('href', hrefAttrValue);
  linkElement.click();
}
</script>

<template>
  <div class="links-container">
    <div class="header">
      <div class="header-title">
        <span class="header-text">{{ strings.header }}</span>
        <WsBadge label="BETA" aid="LINKS_BETA_BADGE"></WsBadge>
      </div>
      <WsButton
        v-if="links.length"
        aid="ADD_LINK_BUTTON"
        :label="strings.addLink"
        color="primary"
        @click="openAddEditLinkDialog()"
      />
    </div>

    <div
      v-if="!links.length && !isLoading"
      aid="EMPTY_STATE_CONTAINER"
      class="empty-state-container"
    >
      <img class="empty-state-logo" alt="links" :src="logo" />
      <span class="empty-state-header">{{ strings.emptyStateHeader }}</span>
      <span class="empty-state-text">{{ strings.emptyStateText }}</span>
      <WsButton
        aid="ADD_LINK_EMPTY_STATE_BUTTON"
        :label="strings.addLink"
        color="primary"
        size="lg"
        @click="openAddEditLinkDialog()"
      />
    </div>

    <div v-if="links.length || isLoading" class="table-container">
      <q-table
        aid="LINKS_TABLE"
        icon-prev-page="fa-regular fa-chevron-left"
        icon-next-page="fa-regular fa-chevron-right"
        :rows="links"
        :columns="columns"
        row-key="slug"
        :loading="isLoading"
        :pagination="{ rowsPerPage: 10, sortBy: 'createdAt', descending: true }"
        no-data-label="No links available. Please add a new link."
        binary-state-sort
      >
        <template #body-cell-url="props">
          <q-td :props="props">
            <span class="truncated-text">
              <q-tooltip
                anchor="top middle"
                self="center middle"
                :offset="[20, 20]"
                transition-show="scale"
                transition-hide="scale"
              >
                {{
                  buildURL(props.row.redirectUrl, [
                    props.row.type === 'survey' ? 'source=link' : '',
                  ])
                }}
              </q-tooltip>
              {{
                buildURL(props.row.redirectUrl, [
                  props.row.type === 'survey' ? 'source=link' : '',
                ])
              }}
            </span>
          </q-td>
        </template>
        <template #body-cell-link="props">
          <q-td
            :aid="'LINK_FULL_URL_CELL_' + props.row.slug"
            class="td-link"
            :props="props"
          >
            {{ props.row.fullShortLinkURL }}
            <q-icon
              class="copy-icon"
              name="fa-regular fa-copy"
              @click="copyLink(props.row.fullShortLinkURL)"
            >
              <WsTooltip aid="COPY_LINK_TOOLTIP">{{
                copyTooltipText
              }}</WsTooltip>
            </q-icon>
          </q-td>
        </template>
        <template #body-cell-clicks="props">
          <q-td :props="props">
            <q-tooltip
              v-if="props.row.lastClick"
              anchor="top middle"
              self="center middle"
            >
              Last clicked {{ props.row.lastClick }}
            </q-tooltip>
            {{ props.row.clicks || 'N/A' }}
          </q-td>
        </template>
        <template #body-cell-action="props">
          <q-td :props="props">
            <WsIconButton :aid="'MENU_BUTTON_' + props.row.slug" variant="text">
              <span class="fa-solid fa-ellipsis" />

              <WsMenu :aid="'LINK_MENU_' + props.row.slug">
                <WsMenuItem
                  :aid="'MENU_BUTTON_EDIT_' + props.row.slug"
                  icon="fa-light fa-pen"
                  @click="openAddEditLinkDialog(props.row)"
                >
                  {{ strings.menuButtonEdit }}
                </WsMenuItem>
                <WsMenuItem
                  :aid="'MENU_BUTTON_EXPORT_' + props.row.slug"
                  :disabled="!props.row.clicks"
                  icon="fa-light fa-download"
                  @click="exportReport(props.row)"
                >
                  {{ strings.menuButtonExport }}
                  <WsTooltip
                    v-if="!props.row.clicks"
                    aid="MENU_BUTTON_EXPORT_DISABLED_TOOLTIP"
                  >
                    {{ strings.reportExportDisabledTooltip }}
                  </WsTooltip>
                </WsMenuItem>
                <WsMenuItem
                  :aid="'MENU_BUTTON_GENERATE_QR_' + props.row.slug"
                  icon="fa-light fa-qrcode"
                  @click="
                    generateQR(
                      buildURL(props.row.fullShortLinkURL, [
                        'refSource=qr-code',
                        props.row.type === 'survey' ? 'source=link' : '',
                      ]),
                      props.row.title
                    )
                  "
                >
                  {{ strings.menuButtonGenerateQR }}
                </WsMenuItem>

                <WsMenuItem
                  :aid="'MENU_BUTTON_DELETE_' + props.row.slug"
                  icon="fa-light fa-trash-can"
                  @click="openDeleteLinkDialog(props.row)"
                >
                  {{ strings.menuButtonDelete }}
                </WsMenuItem>
              </WsMenu>
            </WsIconButton>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<style scoped>
.links-container {
  margin: 12px 24px;

  .table-container {
    padding-top: 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .header-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

    .header-text {
      margin-right: 10px;
    }
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-state-logo {
    display: block;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    width: 370px;
    height: 244px;
  }

  .empty-state-header {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 5px;
    margin-top: 30px;
  }

  .empty-state-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 30px;
  }
}

.truncated-text {
  display: inline-block;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.q-table td,
.q-table th {
  padding: 7px 0 !important;
}

tr:hover .copy-icon {
  opacity: 1;
}

.copy-icon {
  margin-left: 5px;
  opacity: 0;
  cursor: pointer;
  color: #1e90fa;
}
</style>
